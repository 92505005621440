<template>
  <div>
    <Divider dashed style="color:#fff">资产统计</Divider>
    <h4 class="m-b-10">资产类型分布</h4>
    <Table stripe size="small" :data="deviceModelStaData" :columns="tableColumns"></Table>
    <h4 class="m-b-10 m-t-20">资产类型分布</h4>
    <Table stripe size="small" :data="deviceStatusStaData" :columns="tableColumns"></Table>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tableColumns: [
        { title: '类型', key: 'name' },
        { title: '数量', align: 'center', key: 'quantity' },
        {
          title: '占比',
          align: 'right',
          key: 'ratio',
          render: (h, { row }) => {
            return h('span', parseFloat((row.ratio * 100).toPrecision(12)) + '%')
          }
        }
      ]
    }
  },
  computed: {
    deviceModelStaData () {
      return this.$store.state.comAsset.deviceModelStaData
    },
    deviceStatusStaData () {
      return this.$store.state.comAsset.deviceStatusStaData
    }
  }
}
</script>
